<template>
  <div class="d-flex align-items-center">
    <div class="flex-grow-1">
      <b-form-checkbox
        v-model="selected"
        name="selected"
        switch
        :disabled="mandatoryCheck"
      >
        {{docName}}
      </b-form-checkbox>
    </div>
    <div class="d-flex flex-nowrap ms-2">
      <div class="ms-2">
        <font-awesome-icon
          icon="circle-check"
          v-b-tooltip.hover
          :title="docAvailableTitle"
          :class="docAvailableClasses"
        />
      </div>
      <div class="ms-2">
        <font-awesome-icon
          icon="circle-exclamation"
          v-b-tooltip.hover
          :title="docMandatoryTitle"
          :class="docMandatoryClasses"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalSendMailForm',
  props: {
    item: Object,
  },
  components: { },
  data() {
    return {
      selected: this.item.mandatory,
    };
  },
  computed: {
    docName() {
      console.log('docname', this.item);
      const doc = {
        documentInfo: this.item,
      };
      const a = this.item.documentFilename;
      const b = this.$store.getters['documents/decodeDocumentName'](doc);
      return a || b;
    },
    docAvailableClasses() {
      if (this.item.present) return 'text-success';
      return 'd-none';
    },
    docAvailableTitle() {
      return 'Documento presente';
    },
    mandatoryCheck() {
      return this.item.mandatory;
    },
    docMandatoryClasses() {
      if (this.item.mandatory) return 'text-danger';
      return 'd-none';
    },
    docMandatoryTitle() {
      return 'Obbligatorio';
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
